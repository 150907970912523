.CalendarDay__default:hover {
  color: #ff6f61;
}

.CalendarDay__selected {
  border: 1px solid #ff6f61;
  background: #ff6f61;
  color: white;
}

.CalendarDay__selected:hover {
  background: #ff6f61;
  color: white;
}

.SingleDatePickerInput {
  border-radius: 0px;
  /* box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05); */
  /* padding: 8px 11px 7px; */
  /* overflow: hidden;
  border-color: #d1d5db;
  border-width: 1.5px; */
}

.SingleDatePickerInput_input__focused {
  border-color: #4b5563;
  border-width: 1.5px;
}

.SingleDatePickerInput__withBorder {
  /* border-color: #4b5563; */
  border-width: 0px;
}

.SingleDatePickerInput:focus {
  border-color: #4b5563;
  border-width: 1.5px;
}

.DateInput_input {
  font-weight: 340;
  font-size: medium;
  /* border-radius: 4px; */
  padding: 7px 11px 7px;
  border-bottom-width: 0px;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  /* padding: 8px 11px 7px; */
  overflow: hidden;
  border-color: #d1d5db;
  border-width: 1px;
  border: 1px solid #d1d5db;
}

.DateInput_input:focus {
  border-color: #4b5563;
  border: 1px solid #4b5563;
  --tw-ring-color: #4b5563;
  outline: 0px;
  outline-offset: 0px;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
